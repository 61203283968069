import React from "react";
import "./App.css";
import {
  Route,
  Redirect,
  Router,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import ReactGA from 'react-ga';
import Intro from "./components/intro";
import ChooseCanopy from "./components/chooseCanopy";
import ChooseElectricalType from "./components/chooseElectricalType";
import ChoosePendants from "./components/choosePendants";
import GetCustomSpec from "./components/getCustomSpec";
import ChooseFinish from "./components/chooseFinish";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { GA_ENABLED } from "./utils/constants";

if (GA_ENABLED) {
  ReactGA.initialize('UA-179952193-1');
}

const App = () => {
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    if (GA_ENABLED) {
      trackPageView()               // To track the first pageview upon load
      history.listen(trackPageView) // To track the subsequent pageviews
    }
  }, [history])

  function trackPageView() { 
    ReactGA.pageview(window.location.pathname)
  }

  return (
    <Router history={history}>
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames="fade"
          timeout={300}
          onExit={(node) => {
            if (node != null) {
              node.style.position = "fixed";
              node.style.top = -1 * window.scrollY + "px";
            }
          }}
        >
          <Switch location={location}>
            <Route
              exact
              path="/"
              render={() => {
                return <Redirect to="/intro" />;
              }}
            />
            <Route path="/intro" component={Intro} />
            <Route path="/chooseCanopy" component={ChooseCanopy} />
            <Route
              path="/chooseElectricalType"
              component={ChooseElectricalType}
            />
            <Route path="/choosePendants" component={ChoosePendants} />
            <Route path="/getCustomSpec" component={GetCustomSpec} />
            <Route path="/chooseFinish" component={ChooseFinish} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </Router>
  );
};

export default App;
