import { useEffect } from "react";
//import { useLocation } from "react-router-dom";

// https://reactrouter.com/web/guides/scroll-restoration
const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};

const scrollToRef = (ref) => {
  window.scrollTo({ top: ref.current.offsetTop, behavior: "smooth" });
};

export { ScrollToTop, scrollToRef };
