import pendantData from './rawData/pendants.json'

const importImage = (fileName) => {
  return require('../images/assets/' + fileName).default;
}

const replaceImages = (obj) => {
  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (typeof obj[property] == "object") {
        replaceImages(obj[property]);
      } else if (['image', 'photo', 'smPhoto', 'mdPhoto', 'lgPhoto'].includes(property)) {
        obj[property] = importImage(obj[property])
      } else { /* skip */ }
    }
  }
  return obj;
}

export default replaceImages(pendantData);
