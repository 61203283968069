import React from "react";
import { COLORS } from "../colors/colors";

import { useHistory } from "react-router-dom";

import logo from "../images/assets/hms_logo_transbg.png";

const appMargin = {
  margin: `70px 70px 70px 50px`,
};

const container = {
  borderBottom: `1px solid ${COLORS.hmBrown}`,
};

const flexHeaderLogo = {
  width: "200px",
  height: "20px",
  marginTop: "20px",
  marginBottom: "20px",
};

const headerButton = {
  margin: "20px 0px 20px 20px",
  color: "black",
  textDecoration: "none",
  float: "right",
  fontWeight: 600,
};

const HomeBackModal = ({ title, description, goToHome }) => {
  return (
    <div
      className="modal fade"
      id="homeModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id="exampleModalLongTitle"
              style={{ color: COLORS.hmBrown }}
            >
              {title}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div>{description}</div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              style={{
                borderRadius: 0,
                marginRight: 10,
                backgroundColor: COLORS.hmBrown,
                borderColor: COLORS.hmBrown,
              }}
              className="btn btn-secondary btn-lg"
              data-dismiss="modal"
              onClick={() => goToHome()}
            >
              Go Back to Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

function Header() {
  const history = useHistory();

  const goToHome = () => {
    let path = `/`;
    history.push(path);
  };

  return (
    <div style={container}>
      <div className="row">
        <div className="col-md-6">
          <a href="https://studio.hammerton.com">
            <img
              style={{ ...appMargin, ...flexHeaderLogo }}
              src={logo}
              alt=""
            />
          </a>
        </div>
        <div
          className="col-md-6 justify-content-end"
          style={{ paddingRight: 70 }}
        >
          <a
            style={headerButton}
            href="https://studio.hammerton.com/buy-hammerton-studio-lighting/"
          >
            WHERE TO BUY
          </a>
          <div
            style={{
              ...headerButton,
              marginRight: "40px",
              cursor: "pointer",
            }}
            data-toggle="modal"
            data-target="#homeModal"
          >
            HOME
          </div>
        </div>
      </div>
      <HomeBackModal
        title="Are you sure?"
        description="You will lose all your changes!"
        goToHome={goToHome}
      />
    </div>
  );
}

export default Header;
