import canopies from './rawData/canopies.json'

const importImage = (fileName) => {
  return require('../images/assets/' + fileName).default;
}

const importedCanopies = {}
Object.entries(canopies).forEach(([key, val]) => {
  importedCanopies[key] = {
    sampleImage: importImage(val.sampleImage),
    cards: val.cards.map(card => card ? {
        ...card,
        canopyOrderSelection: importImage(card.canopyOrderSelection)
      }
      : null
    ),
  }
})

const canopyData = () => {
  return importedCanopies
};

export default canopyData;
