import React from "react";
import { COLORS } from "../colors/colors";

const BackModal = ({ title, description, goBack }) => {
  return (
    <div
      className="modal fade"
      id="backModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id="exampleModalLongTitle"
              style={{ color: COLORS.hmBrown }}
            >
              {title}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div>{description}</div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              style={{
                borderRadius: 0,
                marginRight: 10,
                backgroundColor: COLORS.hmBrown,
                borderColor: COLORS.hmBrown,
              }}
              className="btn btn-secondary btn-lg"
              data-dismiss="modal"
              onClick={() => goBack()}
            >
              Go back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BackModal;
