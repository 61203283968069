import finishData from './rawData/finishes.json'

const importImage = (fileName) => {
  return require('../images/assets/' + fileName).default
}

const replaceImages = (obj) => {
  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (typeof obj[property] == "object") {
        replaceImages(obj[property]);
      } else if (property === 'image') {
        obj[property] = importImage(obj[property])
      } else { /* skip */ }
    }
  }
  return obj;
}

const FinishData = () => {
  return replaceImages(finishData)
};

export default FinishData
