import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { COLORS } from "../colors/colors.js";

import Header from "./header.js";
import OrderTotal from "./orderTotal.js";
import SectionTitle from "./sectionTitle.js";
import BackModal from "./backModal.js";

import ElectricalTypeData from "../productData/electricalTypes";
import { ScrollToTop, scrollToRef } from "./scrollToTop.js";

const electricalTypeData = ElectricalTypeData();

const ChooseElectricalType = () => {
  const history = useHistory();
  const location = useLocation();

  // window.addEventListener("popstate", () => {
  //   goBack();
  // });

  const [priceTotal] = useState(location.state.orderInfo.canopyPrice);
  const [canopyTitle] = useState(location.state.orderInfo.canopyName);

  const [isDisabled, setIsDisabled] = useState(true);
  const [showLightOptions, setShowLightOptions] = useState(false);

  const [chosenElectricalType, setChosenElectricalType] = useState("");
  const [chosenElectricalTypePower, setChosenElectricalTypePower] = useState(
    ""
  );
  const [order, setOrder] = useState(location.state.orderInfo);

  const ledPowerRef = useRef(null);

  const nextPage = () => {
    let path = `/choosePendants`;
    order.additionalCords = [];
    history.push({
      pathname: path,
      state: { orderInfo: order },
    });
  };

  const goBack = () => {
    let path = `/chooseCanopy`;
    history.push({
      pathname: path,
      state: { orderInfo: location.state.orderInfo },
    });
  };

  const headerParagraph = () => {
    return "Your custom multi-port can include either LED pendants (shown below left) or incandescent pendants (shown below right), but not both. You can mix and match any pendants within either grouping. Choose the grouping that best suits your desired style.";
  };

  useEffect(() => {
    setChosenElectricalType(location.state.orderInfo.electricalTypeName);
    setChosenElectricalTypePower(location.state.orderInfo.electricalTypePower);
    handleOrderInfoOnBack(
      location.state.orderInfo.electricalTypeName,
      location.state.orderInfo.electricalTypePower
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleElectricalOrderInfo = (electrical, image, power) => {
    setChosenElectricalType(electrical);
    setChosenElectricalTypePower(power);
    setOrder({
      canopyName: location.state.orderInfo.canopyName,
      canopyPhoto: location.state.orderInfo.canopyPhoto,
      pendantTotal: location.state.orderInfo.pendantTotal,
      electricalTypeName: electrical,
      electricalTypePhoto: image,
      electricalTypePower: power,
      pendants: [],
      finishColorName: "",
      finishColorPhoto: "",
      finishCordName: "",
      finishCordPhoto: "",
      totalPrice: 0,
      canopyPrice: location.state.orderInfo.canopyPrice,
      baseCanopyPrice: location.state.orderInfo.baseCanopyPrice,
      additionalCords: [],
    });
  };

  const cardContainer = {
    display: "flex",
    justifyContent: "center",
    border: "none",
    flexWrap: "wrap",
  };

  const card = {
    cursor: "pointer",
    height: "100%",
    width: "80%",
  };

  const cardColumn = {
    padding: 0,
    marginLeft: 40,
    marginRight: 40,
    marginBottom: 20,
  };

  const handleOrderInfoOnBack = (name, power) => {
    const { led, incandescent } = electricalTypeData;
    switch (name) {
      case incandescent.title: {
        handleIncandescentClick();
        break;
      }
      case led.title: {
        handleLEDClick();
        break;
      }
      default:
        break;
    }

    const { power2700K, power3000K } = electricalTypeData.led.colorTemperatures;
    switch (power) {
      case power2700K.title: {
        handle2700KClick();
        break;
      }
      case power3000K.title: {
        handle3000KClick();
        break;
      }
      case "": {
        break;
      }
      default:
        break;
    }
  };

  const handleIncandescentClick = () => {
    const { title, image } = electricalTypeData.incandescent;
    handleElectricalOrderInfo(title, image, "");
    setShowLightOptions(false);
    setIsDisabled(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleLEDClick = () => {
    const { title, image } = electricalTypeData.led;
    handleElectricalOrderInfo(title, image, "");
    setIsDisabled(true);
    setShowLightOptions(true);
    scrollToRef(ledPowerRef);
  };

  const handle2700KClick = () => {
    const { title, image, colorTemperatures } = electricalTypeData.led;
    const powerTitle = colorTemperatures.power2700K.title;
    handleElectricalOrderInfo(title, image, powerTitle);
    setIsDisabled(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handle3000KClick = () => {
    const { title, image, colorTemperatures } = electricalTypeData.led;
    const powerTitle = colorTemperatures.power3000K.title;
    handleElectricalOrderInfo(title, image, powerTitle);
    setIsDisabled(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const ElectricalTypeCard = ({ title, description, image, clickHandler }) => {
    // decide if this card should be marked as `selected`
    const isUserSelected =
      title === chosenElectricalType || title === chosenElectricalTypePower;
    const selectedStyles = isUserSelected
      ? {
          border: "2px solid gray",
        }
      : {};

    return (
      <div className="col-md-4" style={cardColumn}>
        <div
          className="card"
          style={{ ...card, ...selectedStyles }}
          onClick={() => {
            clickHandler();
          }}
        >
          {image ? (
            <img
              className="card-img-top"
              style={{ ...items, ...imageStyles }}
              src={image}
              alt=""
            />
          ) : (
            ""
          )}
          <div className="card-body">
            <h5 className="card-title">{title}</h5>
            <p className="card-text">{description}</p>
          </div>
        </div>
      </div>
    );
  };

  /**
    Electrical Type Section View
   */
  const ElectricalType = () => {
    const { led, incandescent } = electricalTypeData;    
    return (
      <div style={cardContainer}>        
        <ElectricalTypeCard
          title={led.title}
          description={led.description}
          image={led.image}
          clickHandler={handleLEDClick}
        />
        <ElectricalTypeCard
          title={incandescent.title}
          description={incandescent.description}
          image={incandescent.image}
          clickHandler={handleIncandescentClick}
        />
      </div>
    );    
  };

  /**
    Electrical Power Section View.
    Is invisible unless LED is chosen.
   */
  const ElectricalPower = () => {
    const { power2700K, power3000K } = electricalTypeData.led.colorTemperatures;
    return (
      <div
        ref={ledPowerRef}
        style={{
          ...cardContainer,
          ...{ visibility: showLightOptions ? "visible" : "hidden" },
        }}
      >
        <ElectricalTypeCard
          title={power2700K.title}
          description={power2700K.description}
          clickHandler={handle2700KClick}
        />
        <ElectricalTypeCard
          title={power3000K.title}
          description={power3000K.description}
          clickHandler={handle3000KClick}
        />
      </div>
    );
  };

  const header = {
    textAlign: "flex-start",
    marginTop: 40,
    marginBottom: 20,
    color: COLORS.hmBrown,
  };

  const headerP = {
    textAlign: "left",
    maxWidth: "80%",
    fontSize: 18,
  };

  const items = {
    width: "100%",
    background: COLORS.hmGray,
  };

  const imageStyles = {
    objectFit: "contain",
    maxHeight: "300px",
  };

  const appContainer = {
    paddingRight: 70,
    paddingLeft: 70,
  };

  return (
    <div>
      <ScrollToTop />
      <Header />
      <div>
        <div className="row" style={{ minHeight: 1200 }}>
          <div className="col-md-9" style={appContainer}>
            <h2 style={header}>Choose Your Electrical Type</h2>
            <p style={headerP}>{headerParagraph()}</p>

            <SectionTitle title={"Choose One Option"} />
            <ElectricalType />

            {showLightOptions ? (
              <SectionTitle title={"Choose Your LED Color Temperature"} />
            ) : (
              <div />
            )}
            <ElectricalPower />
          </div>
          <OrderTotal
            totalTag="Starting at"
            priceTotal={priceTotal}
            isDisabled={isDisabled}
            nextPage={nextPage}
            chosenProducts={{
              chosenCanopy: location.state.orderInfo.canopyName,
              chosenElectricalType: `${chosenElectricalType} ${chosenElectricalTypePower}`,
            }}
            chosenImages={{
              chosenCanopyImage: location.state.orderInfo.canopyPhoto,
              chosenElectricalTypeImage: "", // chosenElectricalTypeImage - don't show for this
            }}
            chosenAdditionalCords={{}}
          />
        </div>
        <BackModal
          title="Are you sure?"
          description="Your Electrical Type changes will be lost."
          goBack={goBack}
        />
      </div>
    </div>
  );
};

export default ChooseElectricalType;
