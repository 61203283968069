import React from "react";
import { COLORS } from "../colors/colors.js";
import NumberFormat from "react-number-format";

const itemTitle = {
  color: COLORS.hmBrown,
  marginTop: 20,
};

const totalItems = {
  backgroundColor: COLORS.hmLightBrown,
  padding: 15,
  // marginTop: 20,
  borderRadius: 10,
};

const SelectedProduct = ({ name, chosenProduct, chosenImage }) => {
  const selectedImageContainer = {
    width: "100%",
    height: "100px",
  };

  const selectedImage = {
    borderRadius: 10,
    maxWidth: "100%",
    maxHeight: "100%",
  };

  return (
    <div style={totalItems}>
      <h5 style={itemTitle}> {name} </h5>
      <p>{chosenProduct}</p>
      {chosenImage ? (
        <div style={selectedImageContainer}>
          <img src={chosenImage} style={selectedImage} alt="" />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

const OrderTotal = ({
  totalTag,
  priceTotal,
  isDisabled,
  nextPage,
  chosenProducts,
  chosenImages,
  chosenAdditionalCords,
}) => {
  const orderContainer = {
    padding: 0,
    backgroundColor: COLORS.hmLightBrown,
  };

  const orderHeader = {
    marginBottom: 40,
    padding: 20,
  };

  const orderBody = {
    margin: 20,
  };

  return (
    <div className="col-md-3" style={orderContainer}>
      <div
        style={{
          ...orderHeader,
          ...{ borderBottom: `1px solid ${COLORS.hmBrown}` },
        }}
      >
        <div style={{}}>
          <h3
            style={{
              textAlign: "flex-start",
              marginBottom: 20,
              marginTop: 10,
            }}
          >
            Your Selections
          </h3>
          {priceTotal >= 0 ? (
            <div style={{ height: 40, textAlign: "flex-start" }}>
              <b style={{ color: COLORS.hmBrown }}>{totalTag}: </b>
              <NumberFormat
                value={priceTotal}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                renderText={(value) => <i>{value}</i>}
              />
            </div>
          ) : (
            <div style={{ height: 40 }} />
          )}
        </div>
      </div>
      <div className="row" style={orderBody}>
        <div className="col" style={{ paddingLeft: 0 }}>
          <button
            style={{
              borderRadius: 0,
              width: "100%",
              backgroundColor: "#FFFFFF",
              borderColor: COLORS.hmBrown,
              color: COLORS.hmBrown,
            }}
            className="btn btn-secondary btn-lg"
            data-toggle="modal"
            data-target="#backModal"
          >
            Back
          </button>
        </div>
        <div className="col" style={{ paddingLeft: 0 }}>
          {nextPage ? (
            <button
              disabled={isDisabled}
              style={{
                borderRadius: 0,
                width: "100%",
                backgroundColor: COLORS.hmBrown,
                borderColor: COLORS.hmBrown,
              }}
              className="btn btn-secondary btn-lg"
              onClick={() => {
                nextPage();
              }}
            >
              Next
            </button>
          ) : (
            <div />
          )}
        </div>
      </div>
      {chosenProducts.chosenCanopy ? (
        <SelectedProduct
          name="Canopy"
          chosenProduct={chosenProducts.chosenCanopy}
          chosenImage={chosenImages.chosenCanopyImage}
        />
      ) : (
        <div />
      )}
      {chosenProducts.chosenElectricalType ? (
        <SelectedProduct
          name="Electrical"
          chosenProduct={chosenProducts.chosenElectricalType}
          chosenImage={chosenImages.chosenElectricalTypeImage}
        />
      ) : (
        <div />
      )}
      {chosenProducts.showChosenPendants ? (
        <div style={totalItems}>
          <h5 style={itemTitle}>Pendants</h5>
          {chosenProducts.showChosenPendants}
        </div>
      ) : (
        <div />
      )}
      {chosenProducts.chosenColor ? (
        <SelectedProduct
          name="Finish"
          chosenProduct={chosenProducts.chosenColor}
          chosenImage={chosenImages.chosenColorImage}
        />
      ) : (
        <div />
      )}
       {chosenProducts.chosenHousing ? (
        <SelectedProduct
          name="Housing"
          chosenProduct={chosenProducts.chosenHousing}
          chosenImage={chosenImages.chosenHousingImage}
        />
      ) : (
        <div />
      )}
      {chosenProducts.chosenCord ? (
        <SelectedProduct
          name="Cord"
          chosenProduct={chosenProducts.chosenCord}
          chosenImage={chosenImages.chosenCordImage}
        />
      ) : (
        <div />
      )}
      {chosenAdditionalCords.showAdditionalCords ? (
        <div style={totalItems}>
          <h5 style={itemTitle}>Additional Cords</h5>
          <div style={{ marginLeft: 15, marginTop: 20 }}>
            {chosenAdditionalCords.showAdditionalCords}
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default OrderTotal;
