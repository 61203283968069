import electricalTypes from './rawData/electricalTypes.json'

electricalTypes.led.image = require('../images/assets/' + electricalTypes.led.image).default
electricalTypes.incandescent.image = require('../images/assets/' + electricalTypes.incandescent.image).default

const ElectricalTypeData = () => {
  return electricalTypes
}

export default ElectricalTypeData
