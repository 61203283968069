import React from "react";
import { COLORS } from "../colors/colors";

const split = {
  marginBottom: 40,
  color: COLORS.hmBrown,
  borderBottom: `1px solid ${COLORS.hmBrown}`,
  display: "flex",
  justifyContent: "space-between",
};

const titles = {
  marginTop: 20,
  textAlign: "left",
  color: COLORS.hmBrown,
};

const SectionTitle = ({ title, otherText }) => {
  return (
    <div style={split}>
      <h3 style={titles}>{title}</h3>
      {otherText ? <h3 style={titles}>{otherText}</h3> : <div />}
    </div>
  );
};

export default SectionTitle;
