import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { COLORS } from "../colors/colors.js";

import Header from "./header.js";
import OrderTotal from "./orderTotal.js";
import SectionTitle from "./sectionTitle.js";

import CanopyData from "../productData/canopies";
import BackModal from "./backModal.js";
import { ScrollToTop } from "./scrollToTop.js";

const ChooseCanopy = () => {
  var currentOrder = {
    canopyName: "",
    canopyPhoto: "",
    pendantTotal: "",
    electricalTypeName: "",
    electricalTypePhoto: "",
    pendants: [],
    finishColorName: "",
    finishColorPhoto: "",
    finishCordName: "",
    finishCordPhoto: "",
    totalPrice: 0,
    canopyPrice: 0,
    baseCanopyPrice: 0,
  };

  // window.addEventListener("popstate", () => {
  //   goBack();
  // });

  const [priceTotal, setPriceTotal] = useState(0);
  const [baseCanopyPrice, setBaseCanopy] = useState(0);
  const [order, setOrder] = useState(currentOrder);
  const [numPendants, setNumPendants] = useState(0);

  const [chosenCanopy, setChosenCanopy] = useState("");
  const [chosenCanopyImage, setChosenCanopyImage] = useState(null);

  const history = useHistory();
  const location = useLocation();

  const canopyData = CanopyData();

  const nextPage = () => {
    let path = `/chooseElectricalType`;
    order.canopyName = chosenCanopy;
    order.canopyPhoto = chosenCanopyImage;
    order.canopyPrice = priceTotal;
    order.basePrice = baseCanopyPrice;
    order.pendantTotal = numPendants;
    order.electricalTypeName = "";
    order.electricalTypePower = "";
    history.push({
      pathname: path,
      state: { orderInfo: order },
    });
  };

  const goBack = () => {
    let path = `/intro`;
    history.push(path);
  };

  const headerParagraph = () => {
    return "First, specify your preferred canopy shape and configuration from the selections shown. Canopies with up to five pendant ports can be mounted directly to a J-box, while heavier canopies require blocking to mount directly to the building structure.";
  };

  const handleCanopyOrderInfo = (canopy, image, num, price, baseCanopy) => {
    setChosenCanopy(canopy);
    setChosenCanopyImage(image);
    setNumPendants(num);
    setOrder({
      canopyName: canopy,
      canopyPhoto: image,
      pendantTotal: num,
      electricalTypeName: "",
      electricalTypePhoto: "",
      electricalTypePower: "",
      pendants: [],
      finishColorName: "",
      finishColorPhoto: "",
      finishCordName: "",
      finishCordPhoto: "",
      totalPrice: price,
      canopyPrice: price,
      baseCanopyPrice: baseCanopy,
    });
  };

  useEffect(() => {
    setChosenCanopy(location.state.orderInfo.canopyName);
    setChosenCanopyImage(location.state.orderInfo.canopyPhoto);
    setPriceTotal(location.state.orderInfo.canopyPrice);
    setBaseCanopy(location.state.orderInfo.baseCanopyPrice);
    setNumPendants(location.state.orderInfo.pendantTotal);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isNextDisabled = () => {
    return !(chosenCanopy && chosenCanopy.length > 0);
  };

  const appContainer = {
    paddingRight: 70,
    paddingLeft: 70,
  };

  const header = {
    textAlign: "flex-start",
    marginTop: 40,
    marginBottom: 20,
    color: COLORS.hmBrown,
  };

  const headerP = {
    textAlign: "left",
    maxWidth: "80%",
    fontSize: 18,
  };

  const items = {
    width: "100%",
    height: "100%",
    borderBottom: "2px solid rgb(235, 235, 235)",
  };

  const onCardClick = (cardInfo) => {
    const { title, canopyOrderSelection, numPendants, priceTotal, baseCanopyPrice } = cardInfo;
    setPriceTotal(priceTotal);
    handleCanopyOrderInfo(title, canopyOrderSelection, numPendants, priceTotal, baseCanopyPrice);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const CanopyCard = ({ cardInfo }) => {
    const { title, description, canopyOrderSelection } = cardInfo;

    const card = {
      width: "18rem",
      marginBottom: 40,
      cursor: "pointer",
    };

    // decided if this card should be marked as `selected`
    const isUserSelected = title === chosenCanopy;

    const selectedStyles = isUserSelected
      ? {
          border: "2px solid gray",
        }
      : {};

    return (
      <div
        className="card"
        style={{ ...card, ...selectedStyles }}
        onClick={() => {
          onCardClick(cardInfo);
        }}
      >
        <img
          className="card-img-top"
          src={canopyOrderSelection}
          alt=""
          style={items}
        />
        <div className="card-body">
          <h5 className="card-title" style={{ textTransform: "capitalize" }}>
            {title}
          </h5>
          <p className="card-text">{description}</p>
        </div>
      </div>
    );
  };

  const CanopySection = ({ canopyData = {} }) => {
    const { cards, sampleImage } = canopyData;

    const canopyCards = cards.map((cardInfo) => {
      return cardInfo ? <CanopyCard cardInfo={cardInfo} /> : <div />; // empty if no data passed. (ex square canopy)
    });

    const cardContainer = {
      display: "flex",
      justifyContent: "space-between",
      border: "none",
      flexWrap: "wrap",
    };

    return (
      <div style={cardContainer}>
        <div className="col-md-4" style={{ padding: 0, maxWidth: 400 }}>
          {canopyCards[0]}
          {canopyCards[1]}
          {canopyCards[4]}
        </div>

        <div className="col-md-4" style={{ padding: 0, maxWidth: 400 }}>
          {canopyCards[2]}
          {canopyCards[3]}
        </div>

        <div className="col-md-4" style={{ padding: 0, maxWidth: 400 }}>
          <img
            src={sampleImage}
            style={{ height: "100%", width: "100%" }}
            alt=""
          />
        </div>
      </div>
    );
  };

  /**
  Return full page
   */
  return (
    <div>
      <ScrollToTop />
      <Header />
      <div style={{ backgroundColor: "#FFFFFF", overflow: "hidden" }}>
        <div className="row" style={{}}>
          <div className="col-md-9" style={appContainer}>
            <h2 style={header}>Choose Your Canopy</h2>
            <p style={headerP}>{headerParagraph()}</p>

            <SectionTitle title={"ROUND"} />
            <CanopySection canopyData={canopyData.round} />

            <SectionTitle title={"CLUSTER"} />
            <CanopySection canopyData={canopyData.cluster} />

            <SectionTitle title={"SQUARE"} />
            <CanopySection canopyData={canopyData.square} />

            <SectionTitle title={"LINEAR"} />
            <CanopySection canopyData={canopyData.linear} />
          </div>
          <OrderTotal
            totalTag="Starting at"
            priceTotal={priceTotal}
            isDisabled={isNextDisabled()}
            nextPage={nextPage}
            chosenProducts={{ chosenCanopy }}
            chosenImages={{ chosenCanopyImage }}
            chosenAdditionalCords={{}}
          />
        </div>
      </div>
      <BackModal
        title="Are you sure?"
        description="Your Canopy changes will be lost."
        goBack={goBack}
      />
    </div>
  );
};

export default ChooseCanopy;
