import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Form,
  Col,
  InputGroup,
  Button,
  Alert,
  Modal,
  Spinner,
} from "react-bootstrap";
import axios from "axios";

import { COLORS } from "../colors/colors.js";

import BackModal from "./backModal.js";
import Header from "./header.js";
import OrderTotal from "./orderTotal.js";

import { BASE_API_URL } from "../utils/constants";

import ReactPdfRenderer from "./reactPdfRenderer.js";
import { ScrollToTop } from "./scrollToTop.js";

const GetCustomSpec = () => {
  const [isDisabled] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const [profession, setProfession] = useState("I am a ...");
  const [showProfessionSection, setShowProfessionSection] = useState(false);

  const [isRendered, setIsRendered] = useState(false);
  const [fullOrder] = useState(location.state.orderInfo);
  const [fileBlob, setFileBlob] = useState(null);

  const [showSubmitError, setShowSubmitError] = useState(false);
  const [showSubmitConfirm, setShowSubmitConfirm] = useState(false);

  const [validated, setValidated] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const handleCloseConfirm = () => setShowSubmitConfirm(false);
  const handleShowConfirm = () => setShowSubmitConfirm(true);

  const handleCloseError = () => setShowSubmitError(false);
  const handleShowError = () => setShowSubmitError(true);

  const specFormRef = useRef(null);

  /*
    Submit order total to the server.
    Params:
      form: A Form object, that includes the form data to be submitted to the server.
   */
  const sumbitOrderTotal = (form, action, customFileBlob) => {
    const formData = new FormData();

    // add form fields
    const {
      firstName,
      lastName,
      companyName,
      installationAddress,
      email,
      phoneNumber,
    } = form.elements;
    formData.append("firstName", firstName.value);
    formData.append("lastName", lastName.value);
    formData.append("companyName", companyName.value);
    formData.append("installationAddress", installationAddress.value);
    formData.append("email", email.value);
    formData.append("phoneNumber", phoneNumber.value);
    formData.append("profession", profession);

    // add orderTotal
    formData.append("orderTotal", JSON.stringify(location.state.orderInfo));

    // add action
    formData.append("orderAction", action || 'submit') // default to submit

    // add pdf file
    if (customFileBlob || fileBlob) {
      formData.append("pdf-file.pdf", customFileBlob || fileBlob);
    } else {
      console.error("Could not attach pdf document to the form Submit");
    }

    const apiUrl = `${BASE_API_URL}/api/orderTotal`;
    return axios.post(apiUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  useEffect(() => {
    fullOrder.orderID = Date.now();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //FORM SUBMIT BUTTON
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    //var pattern = new RegExp(/^[0-9\b]+$/);
    setSubmitClicked(true);

    // if (!pattern.test(form.elements.phoneNumber)){
    //   setValidated(true);
    //   setSubmitClicked(false);
    //   return
    // }

    if (!form.checkValidity()) {
      setValidated(true);
      setSubmitClicked(false);
      return;
    }

    // submit orderTotal to server
    sumbitOrderTotal(form, 'submit')
      .then((res) => {
        console.log(res.data);
        handleShowConfirm();
        setSubmitClicked(false);
      })
      .catch((err) => {
        handleShowError();
        setSubmitClicked(false);
      });

    setValidated(false);
  };

  useEffect(() => {
    setIsRendered(true);
  }, []);

  const goBack = () => {
    let path = `/chooseFinish`;
    history.push({
      pathname: path,
      state: { orderInfo: location.state.orderInfo },
    });
  };

  const getAndSetFile = (file, buttonClicked = false) => {
    if (!fileBlob) {
      // only set state if unset to avoid recursive rendering
      setFileBlob(file);
    } else if (fileBlob && buttonClicked) { // track orderTotal request when the user presses "Download"
      sumbitOrderTotal(specFormRef.current, 'download', file)
        .then(res => {
          /* no need to followup */
        })
        .catch(err => {
          /* ignore error as it won't affect the user */
        })
    }
  };

  const headerParagraph = () => {
    return "Tell us who you are so that we can best advise you on how  to proceed with your specification.";
  };

  const otherParagraph = () => {
    switch (profession) {
      case "Residential Design Professional":
        return `Download your custom spec sheet and email it directly to your Hammerton residential sales representative to get a formal quote and lead time. Or simply click "SUBMIT" and we'll do it for you, and we'll send a copy of your custom spec sheet to your email address.`;
      case "Lighting Showroom":
        return `Download your custom spec sheet and email it directly to your Hammerton retail sales representative to get a formal quote and lead time. Or simply click "SUBMIT" and we'll do it for you, and we'll send a copy of your custom spec sheet to your email address.`;
      case "Homeowner":
        return `Download your custom spec sheet here, then check the WHERE TO BUY page to find your local authorized dealer. If you'd like us to send it to your nearest Hammerton Studio displaying dealer showroom, click "SUBMIT". We'll also send a copy of your custom spec sheet to your email address. Expect to hear from a showroom representative within five business days.`;
      case "Builder":
        return `Download your custom spec sheet and email it directly to your Hammerton builder sales representative to get a formal quote and lead time. Or simply click "SUBMIT" and we'll do it for you, and we'll send a copy of your custom spec sheet to your email address.`;
      case "Contract Design Professional":
        return `Download your custom spec sheet and email it directly to your Hammerton sales representative to get a formal quote and lead time. Or simply click "SUBMIT" and we'll do it for you, and we'll send a copy of your custom spec sheet to your email address.`;
      default:
        return "";
    }
  };

  const showAdditionalCordsOnTotal = (extraCords) => {
    var UIitems = [];
    for (var i = 0; i < extraCords.length; i++) {
      if (extraCords[i].quantity > 0) {
        UIitems.push(
          <div className="row" key={i}>
            <p>{extraCords[i].title}</p>
            <p>, {extraCords[i].size}</p>
            <p>, {extraCords[i].quantity}ft</p>
          </div>
        );
      }
    }
    return UIitems;
  };

  const ShowSubmitErrorAlert = () => {
    return (
      <>
        <Modal show={showSubmitError} onHide={handleCloseError}>
          <Alert
            variant="danger"
            style={{ backgroundColor: "#FFFFFF", border: "none" }}
            onClose={() => handleCloseError()}
            dismissible
          >
            <Alert.Heading style={{ color: "#e83a3a" }}>Error!</Alert.Heading>
            <p>
              Could not submit your request, please contact Hammerton support.
            </p>
          </Alert>
        </Modal>
      </>
    );
  };

  const ShowSubmitConfirmAlert = () => {
    return (
      <>
        <Modal show={showSubmitConfirm} onHide={handleCloseConfirm}>
          <Alert
            variant="success"
            style={{ backgroundColor: "#FFFFFF", border: "none" }}
            onClose={() => handleCloseConfirm()}
            dismissible
          >
            <Alert.Heading style={{ color: "#42d659" }}>Success!</Alert.Heading>
            <p style={{ color: "#000000" }}>Your custom spec is on its way!</p>
          </Alert>
        </Modal>
      </>
    );
  };

  const FormDataComponent = () => {
    return (
      <Form
        ref={specFormRef}
        style={allPadding}
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Form.Row>
          <Form.Group as={Col} sm="4">
            <Form.Label>First Name (required)</Form.Label>
            <Form.Control
              required
              id="firstName"
              type="text"
              placeholder="ex. Jane"
            />
            {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} sm="4">
            <Form.Label>Last Name (required)</Form.Label>
            <Form.Control
              required
              id="lastName"
              type="text"
              placeholder="ex. Doe"
            />
            {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} sm="4">
            <Form.Label>Company name</Form.Label>
            <InputGroup>
              <Form.Control
                id="companyName"
                type="text"
                placeholder="ex. Designers Unlimited"
                aria-describedby="inputGroupPrepend"
              />
            </InputGroup>
          </Form.Group>
          ​
          <Form.Group as={Col} sm="4">
            <Form.Label>
              Where is this product being installed? (required)
            </Form.Label>
            <Form.Control
              required
              id="installationAddress"
              type="text"
              placeholder="ex. Denver, CO 80205"
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid adress.
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} sm="4">
            <Form.Label>Email (required)</Form.Label>
            <Form.Control
              required
              id="email"
              type="email"
              placeholder="ex. name@example.com"
            />
            <Form.Text className="text-muted">
              A copy of your custom specification submission will be sent to
              this address.
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              Please provide a valid email address.
            </Form.Control.Feedback>
          </Form.Group>
          ​
          <Form.Group as={Col} sm="4">
            <Form.Label>Phone Number (required)</Form.Label>
            <Form.Control
              required
              id="phoneNumber"
              name="phoneNumber"
              type="tel"
              pattern="\(?\d{3}\)?-? *\d{3}-? *-?\d{4}"
              placeholder="xxx-xxx-xxxx"
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid phone number.
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <div
          className="row justify-content-left"
          style={{ alignItems: "left" }}
        >
          <div
            className="col-md-4"
            style={{ display: showProfessionSection ? "block" : "none" }}
          >
            {isRendered ? (
              <ReactPdfRenderer
                order={fullOrder}
                callbackFile={getAndSetFile}
              />
            ) : (
              <Button
                style={{
                  borderRadius: 0,
                  marginRight: 10,
                  backgroundColor: "#FFFFFF",
                  borderColor: COLORS.hmBrown,
                  color: COLORS.hmBrown,
                  display: showProfessionSection ? "block" : "none",
                  minWidth: 150,
                  maxWidth: 250,
                }}
                className="btn btn-secondary btn-lg"
                onClick={() => {
                  setIsRendered(true);
                }}
              >
                Generate PDF
              </Button>
            )}
          </div>
          <div className="col-md-4">
            {submitClicked ? (
              <Button
                style={{
                  borderRadius: 0,
                  marginRight: 10,
                  minWidth: 150,
                  maxWidth: 250,
                  width: "100%",
                  backgroundColor: COLORS.hmBrown,
                  borderColor: COLORS.hmBrown,
                  display: showProfessionSection ? "block" : "none",
                }}
                type="submit"
                className="btn btn-secondary btn-lg"
                disabled
              >
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginBottom: 5, marginRight: 5 }}
                />
                Submit
              </Button>
            ) : (
              <Button
                style={{
                  borderRadius: 0,
                  marginRight: 10,
                  minWidth: 150,
                  maxWidth: 250,
                  width: "100%",
                  backgroundColor: COLORS.hmBrown,
                  borderColor: COLORS.hmBrown,
                  display: showProfessionSection ? "block" : "none",
                }}
                type="submit"
                className="btn btn-secondary btn-lg"
              >
                Submit
              </Button>
            )}
          </div>
        </div>
        <div
          style={{
            marginTop: 20,
            color: "gray",
            fontWeight: "bold",
            fontSize: 16,
          }}
        >
          NOTE: Neither action constitutes placement of an order.
        </div>
      </Form>
    );
  };

  const showChosenPendants = (allChosenPendants) => {
    const selectedImageContainer = {
      width: "100%",
      height: "100px",
    };

    const selectedImage = {
      borderRadius: 10,
      maxWidth: "100%",
      maxHeight: "100%",
    };

    var UIitems = [];
    for (let i = 0; i < allChosenPendants.length; i++) {
      UIitems.push(
        <div key={allChosenPendants[i].name} style={{ marginBottom: "3rem" }}>
          <div style={{}}>
            <div className="row" style={{ margin: 0 }}>
              <div>{allChosenPendants[i].name}</div>
            </div>
            <div className="row" style={{ margin: 0 }}>
              <div>Housing: {allChosenPendants[i].housing}</div>
            </div>
            <div className="row" style={{ margin: 0 }}>
              {allChosenPendants[i].smallSelected !== 0 ? (
                <p style={{ marginRight: 5 }}>
                  Small: x{allChosenPendants[i].smallSelected}{" "}
                  {(allChosenPendants[i].largeSelected !== 0 &&
                    allChosenPendants[i].mediumSelected === 0) ||
                  allChosenPendants[i].mediumSelected !== 0 ? (
                    <b>•</b>
                  ) : (
                    <p />
                  )}
                  {"   "}
                </p>
              ) : (
                <div />
              )}
              {allChosenPendants[i].smPrice === allChosenPendants[i].mdPrice &&
              allChosenPendants[i].mdPrice === allChosenPendants[i].lgPrice ? (
                allChosenPendants[i].mediumSelected !== 0 ? (
                  <p style={{ marginRight: 5 }}>
                    {" "}
                    Standard Size: x{allChosenPendants[i].mediumSelected}{" "}
                    {"   "}
                  </p>
                ) : (
                  <div />
                )
              ) : allChosenPendants[i].mediumSelected !== 0 ? (
                <p style={{ marginRight: 5 }}>
                  {" "}
                  Med: x{allChosenPendants[i].mediumSelected} {"   "}
                </p>
              ) : (
                <div />
              )}
              {allChosenPendants[i].largeSelected !== 0 ? (
                <p>
                  {allChosenPendants[i].mediumSelected !== 0 ? <b>•</b> : ""}{" "}
                  Large: x{allChosenPendants[i].largeSelected}
                </p>
              ) : (
                <div />
              )}
            </div>
          </div>
          <div style={selectedImageContainer}>
            <img
              src={allChosenPendants[i].photo}
              style={selectedImage}
              alt=""
            />
          </div>
        </div>
      );
    }
    return UIitems;
  };

  const header = {
    textAlign: "flex-start",
    marginTop: 40,
    marginBottom: 20,
    color: COLORS.hmBrown,
  };

  const headerP = {
    textAlign: "left",
    maxWidth: "80%",
    fontSize: 18,
  };

  const professionSection = {
    textAlign: "left",
    maxWidth: "70%",
    display: showProfessionSection ? "block" : "none",
  };

  const appContainer = {
    paddingRight: 70,
    paddingLeft: 70,
    marginBottom: 70,
  };

  const allPadding = {
    paddingTop: 0,
    paddingRight: 0,
    display: showProfessionSection ? "block" : "none",
  };

  const dropdown = {
    width: "80%",
  };

  return (
    <div>
      <ScrollToTop />
      <Header />
      <div>
        <div className="row">
          <div className="col-md-9" style={appContainer}>
            <h2 style={header}>Get Your Custom Spec</h2>
            <p style={headerP}>{headerParagraph()}</p>
            <div className="dropdown" style={dropdown}>
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  minWidth: 320,
                  maxWidth: 500,
                  width: "30%",
                  backgroundColor: "#FFFFFF",
                  borderColor: "#FFFFFF",
                  color: "#ababab",
                  borderBottom: `1px solid ${COLORS.hmBrown}`,
                }}
              >
                {profession}
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                style={{ width: "30%", minWidth: 320, maxWidth: 500 }}
              >
                <p
                  className="dropdown-item"
                  onClick={() => {
                    setProfession("Residential Design Professional");
                    setShowProfessionSection(true);
                  }}
                >
                  Residential Design Professional
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => {
                    setProfession("Lighting Showroom");
                    setShowProfessionSection(true);
                  }}
                >
                  Lighting Showroom
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => {
                    setProfession("Homeowner");
                    setShowProfessionSection(true);
                  }}
                >
                  Homeowner
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => {
                    setProfession("Builder");
                    setShowProfessionSection(true);
                  }}
                >
                  Builder
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => {
                    setProfession("Contract Design Professional");
                    setShowProfessionSection(true);
                  }}
                >
                  Contract Design Professional
                </p>
              </div>
            </div>
            <p style={professionSection}>{otherParagraph()}</p>
            <FormDataComponent />
          </div>
          <OrderTotal
            totalTag="Total"
            priceTotal={location.state.orderInfo.totalPrice}
            isDisabled={isDisabled}
            chosenProducts={{
              chosenCanopy: location.state.orderInfo.canopyName,
              chosenElectricalType: `${location.state.orderInfo.electricalTypeName} ${location.state.orderInfo.electricalTypePower}`,
              showChosenPendants: showChosenPendants(
                location.state.orderInfo.pendants
              ),
              chosenColor: location.state.orderInfo.finishColorName,
              chosenHousing: location.state.orderInfo.finishHousingName,
              chosenCord: location.state.orderInfo.finishCordName,
            }}
            chosenImages={{
              chosenCanopyImage: location.state.orderInfo.canopyPhoto,
              chosenElectricalTypeImage: "", // don't show for this
              chosenColorImage: location.state.orderInfo.finishColorPhoto,
              chosenHousingImage: location.state.orderInfo.finishHousingPhoto,
              chosenCordImage: location.state.orderInfo.finishCordPhoto,
            }}
            chosenAdditionalCords={{
              showAdditionalCords: showAdditionalCordsOnTotal(
                location.state.orderInfo.additionalCords
              ),
            }}
          />
        </div>
        <ShowSubmitErrorAlert />
        <ShowSubmitConfirmAlert />
        <BackModal
          title="Are you sure?"
          description="No specification changes will be lost."
          goBack={goBack}
        />
      </div>
    </div>
  );
};

export default GetCustomSpec;
