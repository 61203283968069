import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { COLORS } from "../colors/colors.js";

import Header from "./header.js";
import SectionTitle from "./sectionTitle.js";
import OrderTotal from "./orderTotal.js";
import BackModal from "./backModal.js";

import FinishData from "../productData/finishes";
import DropdownPicker from "./dropdownPicker.js";
import { ScrollToTop, scrollToRef } from "./scrollToTop.js";

const finishData = FinishData();

const ChooseFinish = () => {
  const history = useHistory();
  const location = useLocation();
  const additionalCordsRef = useRef(null);

  // window.addEventListener("popstate", () => {
  //   goBack();
  // });

  const [allPendants] = useState(location.state.orderInfo.pendants);

  const [priceTotal, setPriceTotal] = useState(
    location.state.orderInfo.totalPrice
  );

  const [colorChosen, setColorChosen] = useState(false);
  const [housingChosen, setHousingChosen] = useState(false);
  const [cordChosen, setCordChosen] = useState(false);

  const [showAddCords, setShowAddCords] = useState(false);

  const [chosenColor, setChosenColor] = useState({ name: "", image: "" });
  const [chosenHousing, setChosenHousing] = useState({ name: "", image: "" });
  const [chosenCord, setChosenCord] = useState({ name: "", image: "" });

  const [order, setOrder] = useState(location.state.orderInfo);

  const [additionalCords, setAdditionalCords] = useState(
    location.state.orderInfo.additionalCords
  );

  const nextPage = () => {
    let path = `/getCustomSpec`;
    order.finishColorName = chosenColor.name;
    order.finishColorPhoto = chosenColor.image;
    order.finishCordName = chosenCord.name;
    order.finishCordPhoto = chosenCord.image;
    order.finishHousingName = chosenHousing.name;
    order.finishHousingPhoto = chosenHousing.image;
    order.additionalCords = additionalCords;
    order.totalPrice = priceTotal;
    history.push({
      pathname: path,
      state: { orderInfo: order },
    });
  };

  useEffect(() => {
    if (showAddCords) {
      scrollToRef(additionalCordsRef);
    }
  }, [showAddCords]);

  useEffect(() => {
    if (colorChosen && cordChosen && housingChosen) {
      setOrder({
        canopyName: location.state.orderInfo.canopyName,
        canopyPhoto: location.state.orderInfo.canopyPhoto,
        electricalTypeName: location.state.orderInfo.electricalTypeName,
        electricalTypePhoto: location.state.orderInfo.electricalTypePhoto,
        electricalTypePower: location.state.orderInfo.electricalTypePower,
        pendants: location.state.orderInfo.pendants,
        pendantTotal: location.state.orderInfo.pendantTotal,
        finishColorName: chosenColor.name,
        finishColorPhoto: chosenColor.image,
        finishHousingName: chosenHousing.name,
        finishHousingPhoto: chosenHousing.image,
        finishCordName: chosenCord.name,
        finishCordPhoto: chosenCord.image,
        totalPrice: priceTotal,
        canopyPrice: location.state.orderInfo.canopyPrice,
        baseCanopyPrice: location.state.orderInfo.baseCanopyPrice,
        additionalCords: additionalCords,
      });
    }
  }, [colorChosen, cordChosen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setChosenColor({
      name: location.state.orderInfo.finishColorName,
      image: location.state.orderInfo.finishColorPhoto,
    });
    setChosenCord({
      name: location.state.orderInfo.finishCordName,
      image: location.state.orderInfo.finishCordPhoto,
    });
    setChosenHousing({
      name: location.state.orderInfo.finishHousingName,
      image: location.state.orderInfo.finishHousingPhoto,
    });
    setAdditionalCords(location.state.orderInfo.additionalCords);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const goBack = () => {
    let path = `/choosePendants`;
    location.state.orderInfo.totalPrice = getExactPriceTotalForBack();
    history.push({
      pathname: path,
      state: { orderInfo: location.state.orderInfo },
    });
  };

  const getExactPriceTotalForBack = () => {
    var newPrice = priceTotal;
    for (var i = 0; i < additionalCords.length; i++) {
      newPrice -= additionalCords[i].price;
    }
    // TODO - undo premium markup on going back

    return newPrice;
  };

  const headerFinishParagraph = () => {
    return (
      <div>
        <p>
          The finishes shown here are available with your selection of pendants.
        </p>
        <p>
          Opaque finishes provide a durable coating of rich color and lustrous
          metallic sheen and are ideal for all indoor applications.
        </p>
        <p>
          Translucent finishes, if shown, capture the visual appeal of plated
          metal finishes while showcasing the unique artisan characteristics of
          the underlying metalwork. Note that translucent finishes are not
          recommended for use in high humidity areas such as bath and laundry
          rooms.
        </p>
        <a style={header} href="https://studio.hammerton.com/insights/finishes/" target="_blank">Learn about our finishes</a>
      </div>
    );
  };

  const headerHousingParagram = () => {
    return (
      <div>
        <p>
          Socket colors are complementary to finish options
        </p>
      </div>
    );
  };

  const headerCordsParagraph = () => {
    return (
      <div>
        <p>
          Cord colors are complementary to finish options. Each pendant ships
          with 10 feet of high quality cloth-braided cord that is adjustable
          on-site. Additonal cord length can be specified in the next section.
        </p>
      </div>
    );
  };

  const showChosenPendants = (allChosenPendants) => {
    const selectedImageContainer = {
      width: "100%",
      height: "100px",
    };

    const selectedImage = {
      borderRadius: 10,
      maxWidth: "100%",
      maxHeight: "100%",
    };

    var UIitems = [];
    for (let i = 0; i < allChosenPendants.length; i++) {
      UIitems.push(
        <div key={allChosenPendants[i].name} style={{ marginBottom: "3rem" }}>
          <div style={{}}>
            <div className="row" style={{ margin: 0 }}>
              <div>{allChosenPendants[i].name}</div>
            </div>
            <div className="row" style={{ margin: 0 }}>
              <div>Housing: {allChosenPendants[i].housing}</div>
            </div>
            <div className="row" style={{ margin: 0 }}>
              {allChosenPendants[i].smallSelected !== 0 ? (
                <p style={{ marginRight: 5 }}>
                  Small: x{allChosenPendants[i].smallSelected}{" "}
                  {(allChosenPendants[i].largeSelected !== 0 &&
                    allChosenPendants[i].mediumSelected === 0) ||
                    allChosenPendants[i].mediumSelected !== 0 ? (
                      <b>•</b>
                    ) : (
                      <p />
                    )}
                  {"   "}
                </p>
              ) : (
                  <div />
                )}
              {allChosenPendants[i].smPrice === allChosenPendants[i].mdPrice &&
                allChosenPendants[i].mdPrice === allChosenPendants[i].lgPrice ? (
                  allChosenPendants[i].mediumSelected !== 0 ? (
                    <p style={{ marginRight: 5 }}>
                      {" "}
                      Standard Size: x{allChosenPendants[i].mediumSelected}{" "}
                      {"   "}
                    </p>
                  ) : (
                      <div />
                    )
                ) : allChosenPendants[i].mediumSelected !== 0 ? (
                  <p style={{ marginRight: 5 }}>
                    {" "}
                    Med: x{allChosenPendants[i].mediumSelected} {"   "}
                  </p>
                ) : (
                    <div />
                  )}
              {allChosenPendants[i].largeSelected !== 0 ? (
                <p>
                  {allChosenPendants[i].mediumSelected !== 0 ? <b>•</b> : ""}{" "}
                  Large: x{allChosenPendants[i].largeSelected}
                </p>
              ) : (
                  <div />
                )}
            </div>
          </div>
          <div style={selectedImageContainer}>
            <img
              src={allChosenPendants[i].photo}
              style={selectedImage}
              alt=""
            />
          </div>
        </div>
      );
    }
    return UIitems;
  };

  /**
    Parse a string to the first two words
   */
  const parseSecondWordString = (str) => {
    var splitted = str.split(" ");
    if (splitted.length > 2) {
      return splitted[2];
    } else {
      return splitted[1];
    }
  };

  const showExtraCordsOnTotal = (num, id, name, size) => {
    const addedCords = [...additionalCords];
    var found = false;
    for (var i = 0; i < addedCords.length; i++) {
      if (id === addedCords[i].pendantID) {
        setPriceTotal(priceTotal - addedCords[i].price + finishData.additionalCordsPrice * num);
        addedCords.splice(i, 1);
        found = true;
      }
    }

    if (!found) {
      setPriceTotal(priceTotal + finishData.additionalCordsPrice * num);
      addedCords.push({
        pendantID: id,
        quantity: num,
        title: name,
        size: size,
        price: 6 * num,
      });
    } else {
      addedCords.push({
        pendantID: id,
        quantity: num,
        title: name,
        size: size,
        price: 6 * num,
      });
    }
    setAdditionalCords(addedCords);
  };

  const findCorrectAdditionalCord = (id) => {
    // find pendant by id
    const pendant = additionalCords.find((cord) => cord.pendantID === id);

    return pendant ? pendant.quantity : 0; // no pendant found, default display should be 0
  };

  const showAllAddCords = () => {
    var addCordsRows = [];
    var id = 0;
    for (var i = 0; i < location.state.orderInfo.pendants.length; i++) {
      if (location.state.orderInfo.pendants[i].smallSelected !== 0) {
        for (
          var j = 0;
          j < location.state.orderInfo.pendants[i].smallSelected;
          j++
        ) {
          addCordsRows.push(
            <tr key={`${i},${j}`}>
              <th scope="row">
                {" "}
                {parseStringForAdditionalCords(
                  location.state.orderInfo.pendants[i].name
                )}{" "}
              </th>
              <td>
                {" "}
                {parseSecondWordString(
                  location.state.orderInfo.pendants[i].name
                )}{" "}
              </td>
              <td> Small </td>
              <td>
                <DropdownPicker
                  callback={showExtraCordsOnTotal}
                  pendantID={id}
                  name={location.state.orderInfo.pendants[i].name}
                  size="Small"
                  selectedNum={findCorrectAdditionalCord(id)}
                />
              </td>
            </tr>
          );
          id++;
        }
      }
      if (location.state.orderInfo.pendants[i].mediumSelected !== 0) {
        for (
          var n = 0;
          n < location.state.orderInfo.pendants[i].mediumSelected;
          n++
        ) {
          addCordsRows.push(
            <tr key={`${i},${j},${n}`}>
              <th scope="row">
                {" "}
                {parseStringForAdditionalCords(
                  location.state.orderInfo.pendants[i].name
                )}{" "}
              </th>
              <td>
                {" "}
                {parseSecondWordString(
                  location.state.orderInfo.pendants[i].name
                )}{" "}
              </td>
              <td> Medium </td>
              <td>
                <DropdownPicker
                  callback={showExtraCordsOnTotal}
                  pendantID={id}
                  name={location.state.orderInfo.pendants[i].name}
                  size="Medium"
                  selectedNum={findCorrectAdditionalCord(id)}
                />
              </td>
            </tr>
          );
          id++;
        }
      }
      if (location.state.orderInfo.pendants[i].largeSelected !== 0) {
        for (
          var m = 0;
          m < location.state.orderInfo.pendants[i].largeSelected;
          m++
        ) {
          addCordsRows.push(
            <tr key={i}>
              <th scope="row">
                {" "}
                {parseStringForAdditionalCords(
                  location.state.orderInfo.pendants[i].name
                )}{" "}
              </th>
              <td>
                {" "}
                {parseSecondWordString(
                  location.state.orderInfo.pendants[i].name
                )}{" "}
              </td>
              <td> Large </td>
              <td>
                <DropdownPicker
                  callback={showExtraCordsOnTotal}
                  pendantID={id}
                  name={location.state.orderInfo.pendants[i].name}
                  size="Large"
                  selectedNum={findCorrectAdditionalCord(id)}
                />
              </td>
            </tr>
          );
          id++;
        }
      }
    }
    return addCordsRows;
  };

  const showAdditionalCordsOnTotal = (extraCords) => {
    var UIitems = [];
    for (var i = 0; i < extraCords.length; i++) {
      if (extraCords[i].quantity > 0) {
        UIitems.push(
          <div className="row" key={i}>
            <p>{extraCords[i].title}</p>
            <p>, {extraCords[i].size}</p>
            <p>, +{extraCords[i].quantity}ft</p>
          </div>
        );
      }
    }
    return UIitems;
  };

  const card = {
    maxWidth: "100%",
    cursor: "pointer",
  };

  const Card = ({ title, image, finishType }) => {
    // decide if this card should be marked as `selected`
    const isUserSelected =
      (finishType === "color" && title === chosenColor.name) ||
      (finishType === "housing" && title === chosenHousing.name) ||
      (finishType === "cord" && title === chosenCord.name);
    const selectedStyles = isUserSelected
      ? {
        border: "2px solid gray",
      }
      : {};

    const cardClickHandler = () => {
      switch (finishType) {
        case "color":
          setChosenColor({
            name: title,
            image: image,
          });
          // Translucent color choices have a 15% markup
          var CurrentPrice = location.state.orderInfo.totalPrice;
          if(title === "Oil Rubbed Bronze" || title === "Satin Nickel" || title === "Heritage Brass" || title === "Gunmetal"){
            setPriceTotal(Math.round(CurrentPrice * 1.15));
          }else{
            setPriceTotal(Math.round(CurrentPrice));
          }
          
          setColorChosen(true);
          break;
        case "housing":
          setChosenHousing({
            name: title,
            image: image,
          });
          setHousingChosen(true);
          break;
        case "cord":
          setChosenCord({
            name: title,
            image: image,
          });
          setCordChosen(true);
          break;
        default:
          break;
      }
    };

    return (
      <div className="col-md-2" style={card}>
        <div
          className="card"
          style={{ ...{ width: "10rem" }, ...selectedStyles }}
          onClick={cardClickHandler}
        >
          <img className="card-img-top" src={image} alt="" style={items} />
          <div className="card-body">
            <h5 className="card-title">{title}</h5>
          </div>
        </div>
      </div>
    );
  };

  const Opaque = () => {
    const { opaque } = finishData.finishes;
    const opaqueCards = Object.values(opaque).map((color, i) => {
      const { title, image } = color;
      return <Card title={title} image={image} finishType="color" key={i} />;
    });

    return (
      <div style={{ marginTop: 20 }}>
        <SectionTitle title="Opaque" />
        <div className="row">{opaqueCards}</div>
      </div>
    );
  };

  const Translucent = () => {
    const { translucent } = finishData.finishes;    
    const translucentCards = Object.values(translucent).map((color, i) => {
      const { title, image } = color;
      return <Card title={title} image={image} finishType="color" key={i} />;
    });

    return (
      <div style={{ marginTop: 20 }}>
        <SectionTitle title="Translucent - 15% upcharge" />
        <div className="row">{translucentCards}</div>
      </div>
    );
  };

  const HousingColors = () => {
    const { housings } = finishData;
    const housingCards = Object.values(housings).map((housing, i) => {
      const { title, image } = housing;
      return <Card title={title} image={image} finishType="housing" key={i} />;
    });

    return (
      <div style={{ marginTop: 20 }}>
        <h2 style={header}>Choose Your Housing</h2>
        <div style={headerP}>{headerHousingParagram()}</div>
        <div className="row">{housingCards}</div>
      </div>
    );
  };

  const Cords = () => {
    const { cords } = finishData;
    const cordCards = Object.values(cords).map((cord, i) => {
      const { title, image } = cord;
      return <Card title={title} image={image} finishType="cord" key={i} />;
    });

    return (
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <h2 style={header}>Choose Your Cords</h2>
        <div style={headerP}>{headerCordsParagraph()}</div>
        <div className="row" style={{ marginTop: 40 }}>
          {cordCards}
        </div>
      </div>
    );
  };

  const AdditionalCords = () => {
    return (
      <div>
        <SectionTitle title="Additional Cord" />
        <p style={headerP}>
          Additional cord for any or all of your pendants can be ordered for $6
          per linear foot, up to a maximum of 15 additional feet per pendant.{" "}
        </p>
        <button
          style={{
            borderRadius: 0,
            marginRight: 10,
            backgroundColor: COLORS.hmBrown,
            borderColor: COLORS.hmBrown,
          }}
          className="btn btn-secondary btn-lg"
          onClick={() => {
            setShowAddCords(true);
          }}
        >
          Add Additional Cords
        </button>
        <table ref={additionalCordsRef} className="table" style={addCordsDiv}>
          <thead>
            <tr>
              <th scope="col" style={{ width: "30%" }}>
                Name
              </th>
              <th scope="col" style={{ width: "30%" }}>
                Color
              </th>
              <th scope="col" style={{ width: "30%" }}>
                Size
              </th>
              <th scope="col" style={{ width: "30%" }}>
                Length (ft)
              </th>
            </tr>
          </thead>
          <tbody>{showAllAddCords()}</tbody>
        </table>
      </div>
    );
  };

  const parseStringForAdditionalCords = (str) => {
    var splitted = str.split(" ");
    if (splitted.length > 2) {
      return splitted[0] + " " + splitted[1];
    } else {
      return splitted[0];
    }
  };

  const Finishes = () => {
    /*
      Disable the translucent section if
      any selected pendants are not whitelisted
      Assumes that if the pendant type is in the title, then its that type
        ex: 'Alto pendant' or 'alto' will both match type: 'alto'
    */
    const showTranslucent = () => {
      let foundFinish = 0;
      for (var i = 0; i < allPendants.length; i++) {
        if (
          allPendants[i].name.toLowerCase().includes("rock") ||
          allPendants[i].name.toLowerCase().includes("alto") ||
          allPendants[i].name.toLowerCase().includes("gem") ||
          allPendants[i].name.toLowerCase().includes("vessel") ||
          allPendants[i].name.toLowerCase().includes("gaia") ||
          allPendants[i].name.toLowerCase().includes("mesa")
        ) {
          foundFinish += 1;
        }
      }
      return foundFinish === allPendants.length;
    };

    if (!showTranslucent()) {
      return <Opaque />;
    }
    return (
      <div>
        <Opaque />
        <Translucent />
      </div>
    );
  };

  const Housings = () => {
    /*
      Only show housing colors on certain incadescent options
    */
    const showHousingColors = () => {
      let foundGlass = 0;
      for (var i = 0; i < allPendants.length; i++) {
        if (
          allPendants[i].name.toLowerCase().includes("coppa") ||
          allPendants[i].name.toLowerCase().includes("ellisse") ||
          allPendants[i].name.toLowerCase().includes("terra")
        ) {
          foundGlass += 1;
        }
      }
      return foundGlass === allPendants.length;
    };

    if (showHousingColors()) {
      return (
        <div>
          <HousingColors />
        </div>
      );
    } else {
      location.state.orderInfo.finishHousingName = "Not applicable";
      location.state.orderInfo.finishHousingPhoto = "#";
    }
    return (<div></div>);
  };

  const isNextDisabled = () => {
    return !(
      chosenColor.name &&
      chosenColor.name.length > 0 &&
      chosenCord.name &&
      chosenCord.name.length > 0
    );
  };

  const header = {
    textAlign: "flex-start",
    marginTop: 40,
    marginBottom: 20,
    color: COLORS.hmBrown,
  };

  const headerP = {
    textAlign: "left",
    maxWidth: "80%",
    fontSize: 18,
  };

  const items = {
    width: "100%",
    height: "100%",
    backgroundColor: COLORS.hmGray,
  };

  const addCordsDiv = {
    marginTop: 20,
    display: showAddCords ? "block" : "none",
  };

  const appContainer = {
    paddingRight: 70,
    paddingLeft: 70,
    marginBottom: 70,
  };

  return (
    <div>
      <ScrollToTop />
      <Header />
      <div>
        <div className="row">
          <div className="col-md-9" style={appContainer}>
            <h2 style={header}>Choose Your Finish</h2>
            <div style={headerP}>{headerFinishParagraph()}</div>

            <Finishes />
            <Housings />
            <Cords />
            <AdditionalCords />
          </div>
          <OrderTotal
            totalTag="Total"
            priceTotal={priceTotal}
            isDisabled={isNextDisabled()}
            nextPage={nextPage}
            chosenProducts={{
              chosenCanopy: location.state.orderInfo.canopyName,
              chosenElectricalType: `${location.state.orderInfo.electricalTypeName} ${location.state.orderInfo.electricalTypePower}`,
              showChosenPendants: showChosenPendants(
                location.state.orderInfo.pendants
              ),
              chosenHousing: chosenHousing.name,
              chosenColor: chosenColor.name,
              chosenCord: chosenCord.name,
            }}
            chosenImages={{
              chosenCanopyImage: location.state.orderInfo.canopyPhoto,
              chosenElectricalTypeImage: "", // don't show for this
              chosenColorImage: chosenColor.image,
              chosenHousingImage: chosenHousing.image,
              chosenCordImage: chosenCord.image,
            }}
            chosenAdditionalCords={{
              showAdditionalCords: showAdditionalCordsOnTotal(additionalCords),
            }}
          />
        </div>
        <BackModal
          title="Are you sure?"
          description="Your Finish changes will be lost."
          goBack={goBack}
        />
      </div>
    </div>
  );
};

export default ChooseFinish;
