import React from "react";

const DropdownPicker = ({ callback, pendantID, name, size, selectedNum }) => {
  return (
    <div className="dropdown">
      <button
        className="btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style={{
          backgroundColor: "#FFFFFF",
          borderColor: "#FFFFFF",
          color: "#ababab",
        }}
      >
        {selectedNum}
      </button>
      <div
        className="dropdown-menu"
        aria-labelledby="dropdownMenuButton"
        style={{ maxHeight: 200, overflowY: "scroll" }}
      >
        <p
          className="dropdown-item"
          onClick={() => {
            callback(0, pendantID, name, size);
          }}
        >
          0
        </p>
        <p
          className="dropdown-item"
          onClick={() => {
            callback(1, pendantID, name, size);
          }}
        >
          1
        </p>
        <p
          className="dropdown-item"
          onClick={() => {
            callback(2, pendantID, name, size);
          }}
        >
          2
        </p>
        <p
          className="dropdown-item"
          onClick={() => {
            callback(3, pendantID, name, size);
          }}
        >
          3
        </p>
        <p
          className="dropdown-item"
          onClick={() => {
            callback(4, pendantID, name, size);
          }}
        >
          4
        </p>
        <p
          className="dropdown-item"
          onClick={() => {
            callback(5, pendantID, name, size);
          }}
        >
          5
        </p>
        <p
          className="dropdown-item"
          onClick={() => {
            callback(6, pendantID, name, size);
          }}
        >
          6
        </p>
        <p
          className="dropdown-item"
          onClick={() => {
            callback(7, pendantID, name, size);
          }}
        >
          7
        </p>
        <p
          className="dropdown-item"
          onClick={() => {
            callback(8, pendantID, name, size);
          }}
        >
          8
        </p>
        <p
          className="dropdown-item"
          onClick={() => {
            callback(9, pendantID, name, size);
          }}
        >
          9
        </p>
        <p
          className="dropdown-item"
          onClick={() => {
            callback(10, pendantID, name, size);
          }}
        >
          10
        </p>
        <p
          className="dropdown-item"
          onClick={() => {
            callback(11, pendantID, name, size);
          }}
        >
          11
        </p>
        <p
          className="dropdown-item"
          onClick={() => {
            callback(12, pendantID, name, size);
          }}
        >
          12
        </p>
        <p
          className="dropdown-item"
          onClick={() => {
            callback(13, pendantID, name, size);
          }}
        >
          13
        </p>
        <p
          className="dropdown-item"
          onClick={() => {
            callback(14, pendantID, name, size);
          }}
        >
          14
        </p>
        <p
          className="dropdown-item"
          onClick={() => {
            callback(15, pendantID, name, size);
          }}
        >
          15
        </p>
      </div>
    </div>
  );
};

export default DropdownPicker;
